
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useForm, useField } from "vee-validate";
import FormOTP from "@/components/Ui/FormOTP.vue";
import OTPResend from "@/components/Ui/OTPResend.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import useAuth from "@/composables/useAuth";
import useMetaTitle from "@/composables/useMetaTitle";

export default defineComponent({
  name: "VerifyPage",
  components: {
    FormOTP,
    FormButton,
    OTPResend,
  },
  setup() {
    const router = useRouter();
    const { handleSubmit } = useForm();
    const { value: otp } = useField("otp");
    const { verify, verifyStatus, uuid, userDetailsStatus, verifyError } =
      useAuth();
    useMetaTitle("تایید");

    if (!uuid.value) {
      router.push({ name: "Auth" });
    }

    const onSubmit = handleSubmit(async (values) => {
      if (verifyStatus.value === "loading") {
        return;
      }
      await verify(values.otp);
    });

    const onType = async (code: string) => {
      code.length === 6 &&
        !verifyError.value &&
        (await verify(otp.value as string));
    };

    return {
      otp,
      onSubmit,
      verifyStatus,
      userDetailsStatus,
      onType,
    };
  },
});
