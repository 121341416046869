import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-input" }
const _hoisted_2 = { class: "form-input__inner" }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "tel",
        class: "form-input__html text-center",
        inputmode: "decimal",
        number: "true",
        egative: "true",
        integral: "true",
        style: {"background-color":"rgb(255, 255, 255)"},
        value: _ctx.modelValue,
        onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args))),
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value as string))),
        minlength: "6",
        maxlength: "6",
        autofocus: "",
        ref: "inputRef"
      }, null, 40, _hoisted_3)
    ])
  ]))
}