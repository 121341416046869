
import { defineComponent, onBeforeUnmount, ref, computed } from "vue";
import useApi from "@/composables/useApi";
import { ResendResponse } from "@/types/auth";
import useAuth from "@/composables/useAuth";
import useConfig from "@/composables/useConfig";
import { ErrorType } from "@/types/base";

export default defineComponent({
  name: "OTPResend",
  setup() {
    const defaultInterval = ref<number>(120);
    const time = ref<number>(defaultInterval.value);
    const { resend_verify_interval } = useConfig();

    if (resend_verify_interval.value) {
      time.value = resend_verify_interval.value;
    }

    const { call, status, error, response } = useApi<ResendResponse>();
    const { uuid, setError, clearError } = useAuth();

    let timeInterval: number;

    const setTimeInterval = () => {
      timeInterval = setInterval(() => {
        if (time.value > 0) time.value -= 1;
      }, 1000);
    };

    setTimeInterval();

    onBeforeUnmount(() => {
      clearInterval(timeInterval);
    });

    const formatedTime = computed(() =>
      new Date(time.value * 1000).toISOString().substr(14, 5)
    );

    const resend = async () => {
      await call({
        url: "/auth/resend",
        method: "POST",
        data: {
          uuid: uuid.value,
        },
      });

      if (status.value === "error") {
        const errors = error.value as unknown as ErrorType;
        setError(errors);
        time.value = 0;
      }

      if (status.value === "success" && response.value) {
        clearError();
        clearInterval(timeInterval);
        time.value = response.value?.interval;
        setTimeInterval();
      }
    };

    return {
      time,
      formatedTime,
      resend,
    };
  },
});
