
import { defineComponent } from "vue";
import AuthCard from "@/components/Layout/Auth/AuthCard.vue";
import Notify from "@/components/Ui/Notify.vue";
import useAuth from "@/composables/useAuth";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "AuthLayout",
  components: {
    AuthCard,
    Notify,
  },
  setup() {
    const { errors, clearError } = useAuth();
    const breakpoints = useBreakpoints();

    return {
      errors,
      clearError,
      breakpoints,
    };
  },
});
