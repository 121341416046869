
import { defineComponent } from "vue";
import { VariantTypes as Types } from "@/constants";

export default defineComponent({
  name: "Notify",
  props: {
    title: {
      required: true,
      type: String,
    },
    variant: {
      required: false,
      type: String,
      validator: (val: string) => Types.includes(val),
      default: Types[0],
    },
  },
  emits: ["close"],
});
