import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e68cfe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-layout" }
const _hoisted_2 = { class: "row justify-content-center" }
const _hoisted_3 = { class: "col-12 col-lg-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notify = _resolveComponent("notify")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_auth_card = _resolveComponent("auth-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.breakpoints.isMobile ? 'main-container' : 'container'])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_TransitionGroup, {
            "enter-active-class": "animate__animated animate__fadeIn",
            "leave-active-class": "animate__animated animate__fadeOut",
            class: "animate__faster",
            mode: "out-in",
            tag: "div"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, index) => {
                return (_openBlock(), _createBlock(_component_notify, {
                  key: index,
                  variant: "danger",
                  title: "خطا!",
                  onClose: ($event: any) => (_ctx.clearError(index))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(error, (e, key) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                        _createTextVNode(_toDisplayString(e), 1)
                      ], 64))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["onClose"]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_auth_card, {
            title: _ctx.$route.meta.title
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }, 8, ["title"])
        ])
      ])
    ], 2)
  ]))
}