
import { defineComponent, ref, nextTick } from "vue";

export default defineComponent({
  name: "FormOTP",
  props: {
    modelValue: {
      required: false,
      type: String,
      default: null,
    },
  },
  setup() {
    const inputRef = ref<HTMLInputElement>();

    nextTick(() => {
      inputRef.value && inputRef.value.focus();
    });

    const onKeyDown = (e: KeyboardEvent) => {
      const persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
      const englishNumbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const numbers = [...persianNumbers, ...englishNumbers];

      if (
        numbers.includes(e.key) ||
        e.key === "Backspace" ||
        e.key === "Delete"
      ) {
        return;
      } else {
        e.preventDefault();
      }
    };

    return {
      inputRef,
      onKeyDown,
    };
  },
});
