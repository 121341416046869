import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_OTP = _resolveComponent("form-OTP")!
  const _component_OTP_resend = _resolveComponent("OTP-resend")!
  const _component_form_button = _resolveComponent("form-button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
  }, [
    _createVNode(_component_form_OTP, {
      modelValue: _ctx.otp,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.otp) = $event)),
        _ctx.onType
      ]
    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_OTP_resend, { class: "text-center mt-4 py-2" }),
    _createVNode(_component_form_button, {
      type: "submit",
      disabled: !_ctx.otp || _ctx.otp.length < 6,
      loading: _ctx.verifyStatus === 'loading' || _ctx.userDetailsStatus === 'loading',
      ref: "buttonRef",
      "full-width": "",
      label: "ادامه"
    }, null, 8, ["disabled", "loading"])
  ], 32))
}