import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-380a1ac0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "otp-resend" }
const _hoisted_2 = {
  key: 0,
  class: "otp-resend__time"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.time > 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.formatedTime), 1))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          type: "button",
          class: "otp-resend__button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resend && _ctx.resend(...args)))
        }, " ارسال مجدد کد "))
  ]))
}