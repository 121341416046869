
import { defineComponent, ref, watch } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { MOBILE_REGEX } from "@/regex";
import FormInput from "@/components/Ui/FormInput.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import useAuth from "@/composables/useAuth";
import useMetaTitle from "@/composables/useMetaTitle";
import useConfig from "@/composables/useConfig";

const validationSchema = yup.object({
  mobile: yup
    .string()
    .matches(MOBILE_REGEX, {
      message: "شماره همراه نامعتبر است.",
    })
    .required("پر کردن اطلاعات این فیلد اجباری است."),
});

export default defineComponent({
  name: "AuthPage",
  components: {
    FormInput,
    FormButton,
  },
  setup() {
    const { handleSubmit, errors, meta } = useForm({
      validationSchema,
    });
    const { value: mobile } = useField<string>("mobile");
    const { googlerecaptcha_site_key } = useConfig();
    const { login } = useAuth();
    const loading = ref(false);
    const isGoogleCaptchaReady = ref(false);
    useMetaTitle("احراز هویت");

    // add google recaptcha
    const addGoogleRecaptcha = (googlerecaptcha_site_key: string) => {
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${googlerecaptcha_site_key}`
      );
      scriptTag.setAttribute("type", "text/javascript");
      document.querySelector("head")?.append(scriptTag);
    };

    watch(
      () => googlerecaptcha_site_key.value,
      (value) => {
        if (value) {
          addGoogleRecaptcha(value);
        }
      },
      {
        immediate: true,
      }
    );

    const submitForm = () => {
      const grecaptcha = (window as any).grecaptcha;
      loading.value = true;

      grecaptcha.ready(function () {
        isGoogleCaptchaReady.value = true;

        grecaptcha
          .execute(googlerecaptcha_site_key.value, {
            action: "submit",
          })
          .then(function (token: string) {
            login({ mobile: mobile.value, google_response: token }).finally(
              () => {
                loading.value = false;
              }
            );
          });
      });
    };

    const onSubmit = handleSubmit(() => {
      if (loading.value) {
        return;
      }

      submitForm();
    });

    const onType = (mobile: string) => {
      mobile.length === 11 && submitForm();
    };

    return {
      mobile,
      errors,
      meta,
      onSubmit,
      loading,
      isGoogleCaptchaReady,
      onType,
    };
  },
});
