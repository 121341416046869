
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import Logo from "@/components/Ui/Logo.vue";
import useAuth from "@/composables/useAuth";

export default defineComponent({
  name: "AuthCard",
  components: {
    Logo,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
  },
  setup() {
    const router = useRouter();
    const { clearError } = useAuth();

    const onClick = () => {
      clearError();
      void router.push({ name: "Auth" });
    };

    return {
      onClick,
    };
  },
});
