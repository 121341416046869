import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_button = _resolveComponent("form-button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
  }, [
    _createVNode(_component_form_input, {
      modelValue: _ctx.mobile,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mobile) = $event)),
        _ctx.onType
      ],
      label: "شماره همراه",
      type: "tel",
      autofocus: "",
      error: (_ctx.meta.dirty && Boolean(_ctx.errors.mobile)) || Boolean(_ctx.errors.mobile),
      class: "mb-5",
      maxlength: 11
    }, null, 8, ["modelValue", "error", "onUpdate:modelValue"]),
    _createVNode(_component_form_button, {
      type: "submit",
      disabled: (!_ctx.mobile || _ctx.errors.mobile) && _ctx.isGoogleCaptchaReady,
      loading: _ctx.loading,
      "full-width": "",
      label: "ادامه"
    }, null, 8, ["disabled", "loading"])
  ], 32))
}